import {Avatar, Card} from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import getDesignToken from "antd/es/theme/getDesignToken";

interface NpcSpeakCardProps {
  avatar_image: string,
  name: string,
  description: string | null,
  username: string,
  multiline_description: string[]|null,
}

const NpcSpeakCard = (props: NpcSpeakCardProps) => {
  const {avatar_image, name, description, username, multiline_description} = props

  const fontSize = getDesignToken().fontSizeXL
  const headFontSize = getDesignToken().fontSizeHeading1

  let element = <div></div>

  if (description !== null && description !== undefined) {
    element = (
      <Card bordered={false} bodyStyle={{fontSize: fontSize}} headStyle={{fontSize: headFontSize}} >
        <Meta
          avatar={<Avatar src={avatar_image} />}
          title={name}
          description={description.replace('User', username)}
        />
      </Card>
    )
  } else if (multiline_description !== null && multiline_description !== undefined) {
    const multiline_description_element = multiline_description.map((item, i) => {
      return <p key={i}>{item.replace('User', username)}</p>;
    });
    element = (
      <Card bordered={false} bodyStyle={{fontSize: fontSize}} headStyle={{fontSize: headFontSize}} >
        <Meta
          avatar={<Avatar src={avatar_image} />}
          title={name}
          description={multiline_description_element}
        />
      </Card>
    )
  }
  return element
};

export default NpcSpeakCard;