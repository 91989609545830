import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import {Button, Result} from "antd";
import {WarningOutlined} from "@ant-design/icons";

export default function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 403) {
    // the response json is automatically parsed to
    // `error.data`, you also have access to the status
    return (
      <Result
        icon={<WarningOutlined />}
        status="error"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={<Button type="primary" href={"/"}>Back Home</Button>}
      />
    );
  } else if(isRouteErrorResponse(error) && error.status === 404) {
    return (
      <Result
        icon={<WarningOutlined />}
        status="error"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" href={"/"}>Back Home</Button>}
      />
    )} else if(isRouteErrorResponse(error) && error.status === 409) {
    return (
      <Result
        icon={<WarningOutlined />}
        status="error"
        title="409"
        subTitle="Sorry, user with this email already exists."
        extra={<Button type="primary" href={"/"}>Back Home</Button>}
      />
    )} else {
    return (
      <Result
        icon={<WarningOutlined />}
        status="error"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary" href={"/"}>Back Home</Button>}
      />
    )
  }
}
