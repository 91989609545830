import {List, Typography} from "antd";
import getDesignToken from "antd/es/theme/getDesignToken";

interface SimpleBulletedListProps {
  title: string,
  arrayOfElements: string[],
}

const SimpleBulletedList = (props: SimpleBulletedListProps) => {
  const {title, arrayOfElements} = props
  console.log(arrayOfElements)
  return (
    <List
      header={<Typography.Text style={{fontSize: getDesignToken().fontSizeXL}} strong>{title}</Typography.Text>}
      bordered
      dataSource={arrayOfElements}
      renderItem={(item) => (
        <List.Item>
          <Typography.Text style={{fontSize: getDesignToken().fontSizeXL}}>• {item}</Typography.Text>
        </List.Item>
      )}
    />
  )
}

export default SimpleBulletedList;