import React from 'react';
import {Button, Card, Col, ConfigProvider, Divider, Row, Statistic} from 'antd';
import {ArrowUpOutlined, InfoCircleFilled, CreditCardFilled} from '@ant-design/icons';
import Paragraph from "antd/es/typography/Paragraph";
import {Link, useLoaderData} from "react-router-dom";
import {ProfileLoaderData} from "../../lib/loaders";

const Profile: React.FC = () => {
  const loaderData = useLoaderData() as ProfileLoaderData;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'black',
        },
      }}
    >
      <Row>
        <Divider>Profile</Divider>
      </Row>
      <Row>
        <Col flex="1 1 30%">
        </Col>
        <Col flex="1 1 40%">
          <Row>
            <Col span={12}>
              <Paragraph> Username: {loaderData.username} </Paragraph>
            </Col>
            <Col span={6}>
              <Button type="primary">Змінити імя</Button>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Paragraph> Email: {loaderData.email}  </Paragraph>
            </Col>
            <Col span={6}>
              <Button type="primary">Змінити email</Button>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Paragraph> Password: ********** </Paragraph>
            </Col>
            <Col span={6}>
              <Button type="primary">Змінити пароль</Button>
            </Col>
          </Row>
        </Col>
        <Col flex="1 1 30%">
        </Col>
      </Row>
      <Row>
        <Divider>Courses</Divider>
      </Row>
      <Row>
        <Col flex="1 1 10%">
        </Col>
        <Col flex="1 1 80%">
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Card
              bordered={false}
              actions={[
                <Link to={"/product_ua_1_landing"} onClick={()=> window.location.href='/product_ua_1_landing'}><InfoCircleFilled key="information" style={{fontSize: '16px'}}/></Link>,
                <Link to={"/product_ua_1_landing"} onClick={()=> window.location.href='/product_ua_1_landing'}><CreditCardFilled key="buy" /></Link>,
              ]}
            >
              <Card type={"inner"} onClick={()=> window.location.href='/course/product-ua-1/chapter/1/day/1'} hoverable={true}>
                <Statistic
                  title="Production Course"
                  value={136+"%"}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<ArrowUpOutlined />}
                  suffix="закінчено"
                />
              </Card>
            </Card>
          </Col>
        </Row>
        </Col>
        <Col flex="1 1 10%">
        </Col>
      </Row>
      <Row>
        <Divider>Оплати</Divider>
      </Row>
      <Row>
        <Col flex="1 1 10%">
        </Col>
        <Col flex="1 1 80%">
          1 000 000 $$$
        </Col>
        <Col flex="1 1 10%">
        </Col>
      </Row>
    </ConfigProvider>
)};

export default Profile;