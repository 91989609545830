import { FC } from "react";
import {Form, Input, Button, Card, Typography} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {useLoaderData, useSubmit} from "react-router-dom";
import {CSRFTokenLoaderData} from "../../lib/loaders";
const { Title } = Typography;

export type LoginFormFields =  {
  password: string;
  email: string;
  CSRFToken: string;
}

const LoginForm: FC = () => {
  const loaderData = useLoaderData() as CSRFTokenLoaderData;

  const token = loaderData.token

  const submit = useSubmit();

  const handleSubmit = (values: LoginFormFields) => {
    values.CSRFToken = token
    const valuesObject = {}

    Object.keys(values).forEach((key) => {
      // @ts-ignore
      valuesObject[key] = values[key];
    });
    submit(valuesObject, {"action": "/login", method: "POST"});
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: 500 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Title level={2}>SELART </Title>
        </div>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
        >
          <Form.Item<LoginFormFields>
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item<LoginFormFields>
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              Log in
            </Button>
            Don't have an account{" "}
            <a href="/signup">
              sign up
            </a>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};


export default LoginForm;
