import {Typography} from "antd";
import React from "react";
import getDesignToken from "antd/es/theme/getDesignToken";

const {Paragraph} = Typography;

const SimpleText: React.FC<{text: string, username: string}> = ({text, username}) => (
  <Typography>
    <Paragraph style={{fontSize: getDesignToken().fontSizeXL, textAlign: 'justify'}}>
      {text.replace('User', username)}
    </Paragraph>
  </Typography>
)

export default SimpleText;