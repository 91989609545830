import React from 'react';
import { Image } from 'antd';

const SimpleImage: React.FC<{image_url: string}> = ({image_url}) => (
  <Image.PreviewGroup>
    <Image src={image_url} />
  </Image.PreviewGroup>
);

export default SimpleImage;

