import {Button, Card, Divider, Radio, Row, Space, Typography} from "antd";
import React, {ReactNode, useEffect, useState} from "react";
import getDesignToken from "antd/es/theme/getDesignToken";
import {JSObjectToMap} from "../../lib/utils";

interface indexedAnswers {
  [key: number]: string;
}

interface QuestionProps {
  title: string,
  text: string,
  answers: indexedAnswers,
  correctAnswerIndex: number,
  numberOfQuestions: number,
  questionsAnswered: number,
  setQuestionsAnswered: (value: number) => void,
  setIsAllQuestionsAnswered: (value: boolean) => void,
  setOpenEndDayModal: (value: boolean) => void,
  oldAnswer: number | null,
  questionId: number,
  saveAnswer: (questionId: number, answerIndex: number) => void,
  correctAnswerText: string,
  wrongAnswerText: string,
}

const Question = (props: QuestionProps) => {

  const {
    title,
    text,
    answers,
    correctAnswerIndex,
    numberOfQuestions,
    questionsAnswered,
    setQuestionsAnswered,
    setIsAllQuestionsAnswered,
    setOpenEndDayModal,
    oldAnswer,
    questionId,
    saveAnswer,
    correctAnswerText,
    wrongAnswerText,
  } = props

  const {Text} = Typography;

  const fontSize = getDesignToken().fontSizeLG+3  // magic number 3
  const headFontSize = getDesignToken().fontSizeXL

  let [isAnswered, setIsAnswered] = useState(false)
  let [answerIndex, setAnswerIndex] = useState(0)
  let [answerBlockText, setAnswerBlockText] = useState("")
  let [isAnswerCorrect, setIsAnswerCorrect] = useState(false)

  const generateRadioButtonsComponents = (answers: Object) => {
    let radioGroup: ReactNode[] = []
    const answewrsMap = JSObjectToMap(answers)
    answewrsMap.forEach((answer, index) => {
      radioGroup.push(
        <Radio
          key={index}
          value={Number(index)}
          style={{fontSize: fontSize}}
          onClick={() => setAnswerIndex(Number(index))}
        >{answer}</Radio>
      )
    })
    return radioGroup
  }

  const checkCorrectAnswer =  () => {
    if (oldAnswer === null || oldAnswer === undefined) {
      saveAnswer(questionId, answerIndex)
    }
    if (answerIndex === correctAnswerIndex) {
      setAnswerBlockText(correctAnswerText ? correctAnswerText : "Вірно!");
      setIsAnswerCorrect(true)
    } else {
      setAnswerBlockText(wrongAnswerText ? wrongAnswerText : "Невірно!");
      setIsAnswerCorrect(false)
    }

    const currentQuestionsAnswered = questionsAnswered+1
    setIsAnswered(true)
    if (currentQuestionsAnswered === numberOfQuestions) {
      setIsAllQuestionsAnswered(true)
      setOpenEndDayModal(true)
    }
    setQuestionsAnswered(currentQuestionsAnswered)
  }

  const answerBlockStyle = {
    marginTop: 20,
    fontSize: fontSize,
    color: isAnswerCorrect ? "green" : "red"
  }

  useEffect(() => {
    if (oldAnswer!== null && oldAnswer !== undefined) {
      setAnswerIndex(Number(oldAnswer))
      if (answerIndex === correctAnswerIndex) {
        setAnswerBlockText(correctAnswerText ? correctAnswerText : "Вірно!");
      } else {
        setAnswerBlockText(wrongAnswerText ? wrongAnswerText : "Невірно!");
      }

      setIsAnswered(true)
      if (questionsAnswered === numberOfQuestions) {
        setIsAllQuestionsAnswered(true)
        setOpenEndDayModal(true)
      }
    } else {
      if (questionsAnswered === numberOfQuestions) {
        setIsAllQuestionsAnswered(true)
        setIsAnswered(true)
      }
    }
  }, [setAnswerIndex, oldAnswer, answerIndex, correctAnswerIndex, questionsAnswered, numberOfQuestions, setQuestionsAnswered, correctAnswerText, wrongAnswerText, setIsAllQuestionsAnswered, setOpenEndDayModal])

  return (
    <Card title={title} bordered={true} bodyStyle={{fontSize: fontSize}} headStyle={{fontSize: headFontSize}}>
      <Text style={{fontSize: fontSize}}>{text}</Text>
      <Row>
        <Divider>Варіанти відповідей</Divider>
        <Radio.Group size="large" value={answerIndex} disabled={isAnswered}>
          <Space direction="vertical">
            {generateRadioButtonsComponents(answers)}
          </Space>
        </Radio.Group>
      </Row>
      <Button
        type="primary"
        size="large"
        style={{marginTop: 20}}
        disabled={isAnswered}
        onClick={checkCorrectAnswer}
      >Відповісти</Button>
      <div hidden={!isAnswered}>
        <Divider style={answerBlockStyle}>Коментар</Divider>
        <Text style={{fontSize: fontSize}}>{answerBlockText}</Text>
      </div>
    </Card>
  )
};

export default Question;