import React, {useEffect, useState} from 'react';
import {Affix, Col, ConfigProvider, FloatButton, Row, Space, Typography, Button, Modal} from 'antd';
import MainMenu from "./menu/mainMenu";
import SimpleText from "./novelCoursePageComponents/simpletext";
import Question from "./novelCoursePageComponents/question";
import NpcSpeakCard from "./novelCoursePageComponents/npcSpeakCard";
import SimpleBorderText from "./novelCoursePageComponents/simpleBorderText";
import {Link, useLoaderData} from "react-router-dom";
import {DayLoaderData} from "../lib/loaders";
import {RightSquareOutlined} from '@ant-design/icons';
import { hc } from 'hono/client'
import {SaveTrackType} from "../../functions/api/course/[[route]]";
import SimpleImage from "./novelCoursePageComponents/simpleImage";
import SimpleBulletedList from "./novelCoursePageComponents/simpleBulletedList";
export default function CoursePage() {
  const loaderData = useLoaderData() as DayLoaderData;

  const day = loaderData.day
  const tableOfContents = loaderData.tableOfContents
  const userCourseProgress = loaderData.userCourseProgress
  const username = loaderData.username

  const numberOfQuestions = day.numberOfQuestions
  const chapterId = day.nextChapterId
  const nextDayId = day.nextDayId
  const currentDayId = day.globalOrderId
  const currentDayAnsweredQuestions = userCourseProgress.currentDayAnsweredQuestions[currentDayId]

  const { Title, Paragraph} = Typography;

  const title = day.content[0].title ? day.content[0].title.text : null

  const rows = day.content.slice(1, day.content.length)

  const [isAllQuestionsAnswered, setIsAllQuestionsAnswered] = useState(false)
  const [questionsAnswered, setQuestionsAnswered] = useState(0)

  const [open, setOpenEndDayModal] = useState(false);

  useEffect( () => {
    if (userCourseProgress.progressDayId + 1 < currentDayId){
      setQuestionsAnswered(numberOfQuestions)
    } else {
      setQuestionsAnswered(currentDayAnsweredQuestions ? Object.keys(currentDayAnsweredQuestions).length : 0)
    }
  }, [currentDayAnsweredQuestions, currentDayId, numberOfQuestions, userCourseProgress.progressDayId])

  const saveAnswer = async (questionId: number, answerIndex: number) => {
    const client = hc<SaveTrackType>('/')
    const $post = client.api.course.save_track.$post

    await $post({
      form: {
        questionId: questionId,
        answerIndex: answerIndex,
        currentDayId: currentDayId,
      }
    })
  }

  const handleCancel = () => {
    setOpenEndDayModal(false);
  };

  const generateRowComponents = (rows:Array<any>) => {
    return (
      <div>
        {rows.map((row) => {
          switch (Object.keys(row)[0]) {
            case 'simpleText':
              return (
                <Row align={'middle'} wrap={false}>
                  <Col flex="1 1 10%">
                  </Col>
                  <Col flex="4 8 80%">
                    <SimpleText text={row['simpleText']} username={username}/>
                  </Col>
                  <Col flex="1 1 10%">
                  </Col>
                </Row>
              )
            case 'simpleBorderText':
              return (
                <Row align={'middle'} wrap={false}>
                  <Col flex="1 1 10%">
                  </Col>
                  <Col flex="4 8 80%">
                    <SimpleBorderText text={row['simpleBorderText'].text} username={username}/>
                  </Col>
                  <Col flex="1 1 10%">
                  </Col>
                </Row>
              )
            case 'question':
              return (
                <Row align={'middle'} wrap={false} style={{ padding: '10px 0' }}>
                  <Col flex="1 1 10%">
                  </Col>
                  <Col flex="4 8 80%">
                    <Question
                      text={row['question'].text}
                      title={row['question'].title}
                      answers={row['question'].answers}
                      correctAnswerIndex={row['question'].correctAnswerIndex}
                      numberOfQuestions={numberOfQuestions}
                      questionsAnswered={questionsAnswered}
                      setQuestionsAnswered={setQuestionsAnswered}
                      setIsAllQuestionsAnswered={setIsAllQuestionsAnswered}
                      setOpenEndDayModal={setOpenEndDayModal}
                      oldAnswer={currentDayAnsweredQuestions ? currentDayAnsweredQuestions[row['question'].questionId] : null}
                      questionId={row['question'].questionId}
                      saveAnswer={saveAnswer}
                      correctAnswerText={row['question'].comment}
                      wrongAnswerText={row['question'].comment}
                    />
                  </Col>
                  <Col flex="1 1 10%">
                  </Col>
                </Row>
              )
            case 'npcSpeakCard':
              return (
                <Row align={'middle'} wrap={false} style={{ padding: '10px 0' }}>
                  <Col flex="1 1 10%">
                  </Col>
                  <Col flex="4 8 80%">
                    <NpcSpeakCard avatar_image={row['npcSpeakCard'].avatar_image} name={row['npcSpeakCard'].name} description={row['npcSpeakCard'].description} username={username} multiline_description={row['npcSpeakCard'].multiline_description}/>
                  </Col>
                  <Col flex="1 1 10%">
                  </Col>
                </Row>
              )
            case 'image':
              return (
                <Row align={'middle'} wrap={false} style={{ padding: '10px 0' }}>
                  <Col flex="1 1 10%">
                  </Col>
                  <Col flex="4 8 80%">
                    <SimpleImage image_url={row['image']}/>
                  </Col>
                  <Col flex="1 1 10%">
                  </Col>
                </Row>
              )
            case 'bulletList':
              return (
                <Row align={'middle'} wrap={false} style={{ padding: '10px 0' }}>
                  <Col flex="1 1 10%">
                  </Col>
                  <Col flex="4 8 80%">
                    <SimpleBulletedList arrayOfElements={row['bulletList'].list} title = {row['bulletList'].title}/>
                  </Col>
                  <Col flex="1 1 10%">
                  </Col>
                </Row>
              )
            default:
              return (
                <Paragraph>Unknown type</Paragraph>
              )
          }
        })}
      </div>
    )
  };

  const res = generateRowComponents(rows)

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'black',
        },
      }}
    >
      <Row>
        <Col xs={24} xl={8}>
          <Affix offsetTop={10}>
            <MainMenu tableOfContents={tableOfContents}/>
          </Affix>
        </Col>
      </Row>
      <Row>
        <Col flex="1 1 10%">
        </Col>
        <Col flex="1 1 80%">
          <Typography>
            <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
              <Title level={2}>
                SELART
              </Title>
            </Space>
          </Typography>
        </Col>
        <Col flex="1 1 10%">
        </Col>
      </Row>
      <Row>
        <Col flex="1 1 10%">
        </Col>
        <Col flex="1 1 80%">
          <Typography>
            <Title level={1}>
              {title.replace('User', username)}
            </Title>
          </Typography>
        </Col>
        <Col flex="1 1 10%">
        </Col>
      </Row>
      {res}
      <div hidden={!isAllQuestionsAnswered}>
        <Link to={`/course/product-ua-1/chapter/${chapterId}/day/${nextDayId}`} reloadDocument={true}>
          <FloatButton
            shape="square"
            type="primary"
            style={{ right: 24 }}
            icon={<RightSquareOutlined/>}
          />
        </Link>
      </div>
      <Modal
        open={open}
        title="✅ Вітаємо!"
        onCancel={handleCancel}
        footer={[
          <Button type="primary" href={`/course/product-ua-1/chapter/${chapterId}/day/${nextDayId}`}>
              Перейти
          </Button>,
          <Button type="dashed" onClick={handleCancel}>
            Хочу залишитися
          </Button>,
        ]}
      >
        <p>Ви відповіли на усі питання! Нажміть Перейти, що б почався новий день!</p>
      </Modal>
    </ConfigProvider>
  );
}
