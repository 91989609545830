import {Typography} from "antd";
import React from "react";
import getDesignToken from "antd/es/theme/getDesignToken";

const {Paragraph} = Typography;

const SimpleBorderText: React.FC<{text: string, username: string}> = ({text, username}) => (
  <Typography>
      <Paragraph style={{fontSize: getDesignToken().fontSizeXL, textAlign: 'justify', border: '1px solid black', padding: '10px', color:"rgba(0, 0, 0, 0.6)"}}>
      {text.replace('User', username)}
    </Paragraph>
  </Typography>
)

export default SimpleBorderText;