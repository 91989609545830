import React from 'react';
import ReactDOM from 'react-dom/client';
import CoursePage from './views/coursePage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {dayLoader, getCSRFToken, loginAction, profileLoader, signOutAction, signupAction} from "./lib/loaders";
import Main from "./views/main";
import ErrorBoundary from "./views/specialPages/error";
import LoginForm from "./views/auth/loginForm";
import SignupForm from "./views/auth/signupForm";
import {ConfigProvider} from "antd";
import Profile from "./views/auth/profile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "course/product-ua-1/chapter/:chapterId/day/:dayId",
    element: <CoursePage />,
    loader: dayLoader,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "login",
    element: <LoginForm />,
    action: loginAction,
    loader: getCSRFToken,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "signup",
    element: <SignupForm />,
    action: signupAction,
    loader: getCSRFToken,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "signout",
    loader: signOutAction,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "profile",
    element: <Profile />,
    errorElement: <ErrorBoundary />,
    loader: profileLoader,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'black',
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
);