import { FC } from "react";
import {Form, Input, Button, Card, Typography} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {useLoaderData, useSubmit} from "react-router-dom";
import {CSRFTokenLoaderData} from "../../lib/loaders";
const { Title } = Typography;

export type SignupFormFields =  {
  username: string;
  email: string;
  password: string;
  CSRFToken: string;
}

const SignupForm: FC = () => {
  const loaderData = useLoaderData() as CSRFTokenLoaderData;

  const token = loaderData.token

  const submit = useSubmit();
  const handleSubmit = (values: SignupFormFields) => {
    values.CSRFToken = token
    const valuesObject ={}
    Object.keys(values).forEach((key) => {
      // @ts-ignore
      valuesObject[key] = values[key];
    });
    submit(valuesObject, {"action": "/signup", method: "POST"});
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: 500 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Title level={2}>SELART </Title>
        </div>
        <Form
          name="normal_signup"
          className="signup-form"
          onFinish={handleSubmit}
        >
          <Form.Item<SignupFormFields>
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item<SignupFormFields>
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item<SignupFormFields>
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="signup-form-button"
              block
            >
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};


export default SignupForm;
