import {LoaderFunctionArgs, redirect} from "react-router-dom";
import {CourseType} from "../../functions/api/course/[[route]]";
import { hc } from 'hono/client'
import {Day} from "./bl";
import {GetCSRFTokenType, GetProfileType, LoginType, SignOutType, SignUpType} from "../../functions/api/auth/[[route]]";

export interface DayLoaderData {
  day: {
    content: Array<any>,
    numberOfQuestions: number,
    nextChapterId: number,
    nextDayId: number,
    globalOrderId: number,
    shortTitle: string,
  };
  tableOfContents: {
    string: Array<Day>,
  };
  userCourseProgress: {
    progressDayId: number,
    currentDayAnsweredQuestions: {[key in number]: {[key in number]: number}},
  };
  username: string;
}

export interface ProfileLoaderData {
  username: string;
  email: string;
}

export interface CSRFTokenLoaderData {
  token: string;
}

export async function dayLoader({params}: LoaderFunctionArgs){
  const client = hc<CourseType>('/')
  const $get = client.api.course.get_day.$get

  const result = await $get({
    query: {
      dayId: params.dayId,
      chapterId: params.chapterId,
    },
  })

  if (result.status === 200) {
    return result
  } else {
    throw new Response("Forbidden", { status: 403 });
  }
}

export async function profileLoader(){
  const client = hc<GetProfileType>('/')
  const $get = client.api.auth.profile.$get

  const result = await $get()

  if (result.status === 200) {
    return result
  } else {
    throw new Response("Forbidden", { status: 403 });
  }
}

export async function loginAction({ request }: LoaderFunctionArgs){
  let formData = await request.formData();
  const email = formData.get('email')
  const password = formData.get('password')
  const CSRFToken = formData.get('CSRFToken')

  const client = hc<LoginType>('/')
  const $post = client.api.auth.login.$post

  const result = await $post({
    form: {
      email: email,
      password: password,
      CSRFToken: CSRFToken,
    }
  })
  if (result.status === 200) {
    return redirect("/");
  } else {
    throw new Response("Forbidden", { status: 403 });
  }
}

export async function signupAction({ request }: LoaderFunctionArgs){
  let formData = await request.formData();
  const email = formData.get('email')
  const username = formData.get('username')
  const password = formData.get('password')
  const CSRFToken = formData.get('CSRFToken')

  const client = hc<SignUpType>('/')
  const $post = client.api.auth.signup.$post

  const result = await $post({
    form: {
      email: email,
      username: username,
      password: password,
      CSRFToken: CSRFToken,
    }
  })
  if (result.status === 200) {
    return redirect("/");
  } else {
    throw new Response("Conflict", { status: 409 });
  }
}

export async function signOutAction(){
  const client = hc<SignOutType>('/')
  const $post = client.api.auth.signout.$post

  const result = await $post()
  if (result.status === 200) {
    return redirect("/");
  } else {
    return redirect("/");
  }
}

export async function getCSRFToken(){
  const client = hc<GetCSRFTokenType>('/')
  const $get = client.api.auth.csrf.$get

  const result = await $get()
  if (result.status === 200) {
    return result
  } else {
    return redirect("/");
  }
}