import {Link} from "react-router-dom";

export default function Main() {
  return (
    <div>
      <div>
        <Link to={"course/product-ua-1/chapter/1/day/1"} reloadDocument={true}>
          Go to day 1 of Product Course
        </Link>
      </div>
      <div>
        <Link to={"login"} reloadDocument={true}>
          Login
        </Link>
      </div>
      <div>
        <Link to={"signup"} reloadDocument={true}>
          Signup
        </Link>
      </div>
      <div>
        <Link to={"signout"} reloadDocument={true}>
          Signout
        </Link>
      </div>
      <div>
        <Link to={"profile"} reloadDocument={true}>
          Profile
        </Link>
      </div>
    </div>
  )
}